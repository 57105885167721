* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  /* margin: 0 auto; */
  background-color: #f7f7f7;
  height: 100vh;
}

.nav-tabs .nav-link.active {
  background-color: #0d6efd !important;
  color: #fff !important;
}

.page-title {
  text-align: center;
}

.tab-ctn {
  position: relative;
}

.app-env-ctn {
  position: absolute;
  right: 120px;
  top: -10px;
}

.result-table {
  background-color: #fff;
  border: 1px solid #dee2ec;
}

.input-space {
  padding: 5px 10px;
}

/* .top-header {
  display: flex;
  gap: 20px;
} */

.top-header label {
  display: block;
  padding-top: 10px;
  padding-bottom: 5px;
}

.input-ctn {
  /* width: 50%; */
}

.input-ctn .input-textarea {
  width: 100%;
  padding: 10px;
}

.controls {
  /* width: 50%; */
  display: flex;
}

.controls .slider-ngram-ctn {
  width: 40%;
}

.slider-ngram {
  display: flex;
  gap: 30px;
  padding-top: 10px;
}

.slider-ngram div:nth-child(2) {
  width: 100%;
}

.input-type-ctn .radio-label {
  display: inline;
  padding-left: 5px;
  padding-right: 10px;
}

.threshold-slider {
  width: 60%;
  cursor: pointer;
}

.ngram {
  padding: 5px 10px;
  width: fit-content;
}

.controls .app-env-ctn {
  width: 40%;
}

/* #application-environment {
  font-size: 14px;
  padding: 4px;
  width: 40%;
} */

.controls .result-btn-ctn {
  width: 20%;
}

.result-btn-ctn .result-btn {
  padding: 5px 10px;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
}

.loader {
  text-align: center;
  padding-top: 20px;
}

.result-ctn {
  margin-top: 30px;
}

.result-title {
  padding-bottom: 5px;
}

.result-sql {
  padding-bottom: 20px;
}

.result-query {
  padding-bottom: 20px;
}
